import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import './RegistrationForm.css';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    age: '',
    linkedin: '',
    gender: '',
    experience: '',
    challenges: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const challenges = [
    { id: 'ai', label: 'Inteligență Artificială' },
    { id: 'python', label: 'Python' },
    { id: 'design', label: 'Design' },
    { id: 'frontend', label: 'Frontend' },
    { id: 'cpp', label: 'C++' },
  ];

  const carouselImages = [
    'https://aipro.ro/assets/images/hack/1.png',
    'https://aipro.ro/assets/images/hack/2.png',
    'https://aipro.ro/assets/images/hack/3.png',
    'https://aipro.ro/assets/images/hack/4.png',
    'https://aipro.ro/assets/images/hack/5.png',
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselImages.length);
    }, 3000);

    Fancybox.bind("[data-fancybox]", {
      // Fancybox options here
    });

    return () => {
      clearInterval(timer);
      Fancybox.destroy();
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChallengeChange = (challengeId) => {
    setFormData(prev => {
      const updatedChallenges = prev.challenges.includes(challengeId)
        ? prev.challenges.filter(id => id !== challengeId)
        : [...prev.challenges, challengeId].slice(-2);
      return { ...prev, challenges: updatedChallenges };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('http://aipro.ro/api/register/hackaton', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'A apărut o eroare la înregistrare.');
      }
      
      Swal.fire({
        icon: 'success',
        title: 'Înregistrare reușită!',
        text: 'Vei primi un email de confirmare în curând.',
        confirmButtonColor: '#3085d6',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || 'A apărut o eroare la înregistrare.',
        confirmButtonColor: '#d33',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const changeSlide = (direction) => (e) => {
    e.preventDefault();
    if (direction === 'next') {
      setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
    } else {
      setCurrentSlide((prev) => (prev - 1 + carouselImages.length) % carouselImages.length);
    }
  };

  return (
    <div className="registration-container">
      <div className="registration-form">
        <div className="form-header">
          <h2>Hackathon Aipro 2024</h2>
          <p>Înscrie-te și inovează alături de noi</p>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            name="name"
            type="text"
            required
            placeholder="Nume complet"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            name="email"
            type="email"
            required
            placeholder="Adresă de email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            name="whatsapp"
            type="tel"
            placeholder="Număr WhatsApp"
            value={formData.whatsapp}
            onChange={handleChange}
          />
          <input
            name="age"
            type="number"
            required
            placeholder="Vârstă"
            value={formData.age}
            onChange={handleChange}
          />
          <input
            name="linkedin"
            type="url"
            placeholder="Link profil LinkedIn (opțional)"
            value={formData.linkedin}
            onChange={handleChange}
          />
          <select
            name="gender"
            required
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Selectează genul</option>
            <option value="masculin">Masculin</option>
            <option value="feminin">Feminin</option>
          </select>
          <select
            name="experience"
            required
            value={formData.experience}
            onChange={handleChange}
          >
            <option value="">Selectează experiența</option>
            <option value="beginner">Începător (&lt;1 an)</option>
            <option value="intermediate">Intermediar (1-3 ani)</option>
            <option value="advanced">Avansat (3+ ani)</option>
          </select>

          <div className="carousel">
            <a href={carouselImages[currentSlide]} data-fancybox="gallery">
              <img src={carouselImages[currentSlide]} alt={`Slide ${currentSlide + 1}`} />
            </a>
            <button onClick={changeSlide('prev')} className="carousel-button prev" type="button">&lt;</button>
            <button onClick={changeSlide('next')} className="carousel-button next" type="button">&gt;</button>
          </div>
          <div className="challenges">
            <h3>Alege maxim 2 provocări:</h3>
            {challenges.map((challenge) => (
              <div key={challenge.id} className="challenge-option">
                <input
                  type="checkbox"
                  id={challenge.id}
                  checked={formData.challenges.includes(challenge.id)}
                  onChange={() => handleChallengeChange(challenge.id)}
                  disabled={formData.challenges.length >= 2 && !formData.challenges.includes(challenge.id)}
                />
                <label htmlFor={challenge.id}>{challenge.label}</label>
              </div>
            ))}
          </div>

          <button
            type="submit"
            disabled={isSubmitting || formData.challenges.length === 0}
            className={isSubmitting || formData.challenges.length === 0 ? 'submit-button disabled' : 'submit-button'}
          >
            {isSubmitting ? 'Se procesează...' : 'Înregistrează-te'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
